import { Link } from '@remix-run/react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { useTranslation } from 'react-i18next';
import ProductCard from '#app/components/common/product-card/product-card';
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links';
import PlentyHeading from '#app/components/typography/plenty-heading';
import {
  Carousel,
  CarouselContent,
  CarouselItem } from
'#app/components/ui/carousel';
import { Icon } from '#app/components/ui/icon';
import ClerkProductCard from '#app/features/plp-clerk/clerk-components/clerk-product-card/clerk-product-card';
import useAmplitudeContext from '#app/hooks/use-amplitude-context';
import { useClerkVisitorStore } from '#app/store/clerk-visitor';
import { cn } from '#app/utils/misc';
import { logClickEvent } from '#app/utils/tracking/clerk-tracking';
import { type PlentyProduct, type ClerkProduct } from '#types/product';
import PlentyBody from './typography/plenty-body';
import "../styles/carousel.css?__remix_sideEffect__";

type ProductCarouselProps = {
  clerkProducts?: ClerkProduct[];
  title?: string;
  subtitle?: string;
  linkText?: string;
  linkUrl?: string;
  storeParams?: string[];
  commercetoolsProducts?: PlentyProduct[];
  smallVersion?: boolean;
  tabsOption?: boolean;
  twoRows?: boolean;
  verticalSliderForMobile?: boolean;
};

/**
 * A carousel component for displaying products.
 *
 * @param {Object} props Component props
 * @param {ClerkProduct[]} [props.clerkProducts] Array of Clerk products
 * @param {string} [props.title] Title of the carousel
 * @param {string} [props.subtitle] Subtitle of the carousel
 * @param {string} [props.linkText] Text of the link button
 * @param {string} [props.linkUrl] URL of the link button
 * @param {string[]} [props.storeParams] Store params to pass to the link URL
 * @param {PlentyProduct[]} [props.commercetoolsProducts] Array of Commercetools products
 * @param {boolean} [props.smallVersion] Whether to display the carousel in a small version
 * @param {boolean} [props.tabsOption] Whether to display the carousel with tabs
 * @param {boolean} [props.twoRows] Whether to display the carousel with two rows
 * @param {boolean} [props.verticalSliderForMobile] Whether to display the carousel in a vertical slider for mobile
 *
 * @returns {JSX.Element} The rendered component
 */
export function ProductCarousel({
  clerkProducts,
  title,
  subtitle,
  linkText,
  linkUrl,
  storeParams,
  commercetoolsProducts,
  smallVersion,
  tabsOption = false,
  twoRows = false,
  verticalSliderForMobile
}: ProductCarouselProps) {
  const { clerkVisitor } = useClerkVisitorStore();
  const { t } = useTranslation('common');
  const isClient = typeof window !== 'undefined';
  const { trackAmplitudeEvent } = useAmplitudeContext();
  const [products, CardComponent] = clerkProducts?.length ?
  [clerkProducts, ClerkProductCard] :
  [commercetoolsProducts, ProductCard];

  const productType = clerkProducts?.length ? 'clerk' : 'commercetools';

  const handleClick = (product: any, index: number) => {
    if (productType === 'clerk') {
      trackAmplitudeEvent('carousel product clicked', {
        carousel: title,
        productClicked: product.style_id
      });
      logClickEvent(product.id, index, clerkVisitor!);
    }
  };

  if (!products) return null;

  // Split products into pairs for two-row display
  const productPairs = twoRows ?
  products.reduce((result: any[][], item, index) => {
    if (index % 2 === 0) {
      result.push([item, products[index + 1]].filter(Boolean));
    }
    return result;
  }, []) :
  products.map((product) => [product]);

  return (
    <>
			<div className="flex flex-col space-y-4 overflow-x-auto scrollbar-hide">
				{!tabsOption &&
        <div className="flex w-full justify-between md:flex-row md:items-end md:pr-[var(--plenty-padding)]">
						<div>
							<PlentyHeading as="h8">{title}</PlentyHeading>
							<PlentyBody>{subtitle}</PlentyBody>
						</div>
						{linkUrl && linkText &&
          <Link
            className="group relative flex items-center"
            to={linkUrl || '/'}>

								<PlentyButtonsAndLinks as="btn-sm" className="relative">
									{linkText}
								</PlentyButtonsAndLinks>
								<span className="absolute bottom-0 left-0 h-[4px] w-full bg-beige-50 transition-colors duration-300 group-hover:bg-beige-90" />
								<Icon
              className="ml-2 h-4 w-4 transform transition-transform duration-300 group-hover:translate-x-1"
              name="right-arrow" />

							</Link>}

					</div>}

				<div className="relative">
					{verticalSliderForMobile ?
          <>
							<div className="md:hidden">
								<div className="grid grid-cols-2 gap-2">
									{products.map((product, index) =>
                <Link
                  key={index}
                  to={generateProductLink(
                    product,
                    productType,
                    storeParams
                  )}
                  state={{
                    amplitudePageSource: isClient ?
                    window.location.href :
                    ''
                  }}
                  onClick={() => handleClick(product, index)}>

											<CardComponent
                    product={product}
                    isSlider={false}
                    lazyLoad
                    priority="low"
                    storeParams={storeParams}
                    width="50px"
                    className="overflow-hidden [&_img]:mb-[-22px]" />

										</Link>
                )}
								</div>
							</div>

							<div className="hidden md:block">
								<Carousel
                plugins={[WheelGesturesPlugin()]}
                opts={{
                  dragFree: true,
                  axis: 'x'
                }}>

									<CarouselContent
                  className={`gap-0 ${twoRows ? 'embla-two-rows' : ''}`}>

										{productPairs.map((pair, pairIndex) =>
                  <CarouselItem
                    key={pairIndex}
                    className={`${
                    smallVersion ?
                    'min-w-[168px] sm:min-w-[180px]' :
                    'min-w-[220px]'
                    } basis-[32%] md:min-w-[285px] md:basis-[30%] lg:basis-[18%] xl:basis-[10%] 2xl:basis-[13%] ${
                    twoRows ?
                    'embla-slide-two-rows min-w-[190px] md:min-w-[190px] md:basis-[30%]' :
                    ''
                    }`}>

												<div
                      className={`flex flex-col ${
                      twoRows ? 'h-full gap-2' : ''
                      }`}>

													{pair.map((product, index) =>
                      <Link
                        key={index}
                        to={generateProductLink(
                          product,
                          productType,
                          storeParams
                        )}
                        state={{
                          amplitudePageSource: isClient ?
                          window.location.href :
                          ''
                        }}
                        onClick={() =>
                        handleClick(product, pairIndex * 2 + index)}

                        className={cn(
                          twoRows ?
                          'embla-slide-inner min-h-0 flex-1' :
                          ''
                        )}>

															<CardComponent
                          product={product}
                          isSlider={twoRows ? false : true}
                          isDoubleSlider={twoRows}
                          width={twoRows ? '95px' : ''}
                          lazyLoad
                          priority="low"
                          storeParams={storeParams} />

														</Link>
                      )}
												</div>
											</CarouselItem>
                  )}
									</CarouselContent>
								</Carousel>
							</div>
						</> :

          <Carousel
            plugins={[WheelGesturesPlugin()]}
            opts={{
              dragFree: true,
              axis: 'x'
            }}>

							<CarouselContent
              className={`gap-0 ${twoRows ? 'embla-two-rows' : ''}`}>

								{productPairs.map((pair, pairIndex) =>
              <CarouselItem
                key={pairIndex}
                className={`${
                smallVersion ?
                'min-w-[168px] sm:min-w-[180px]' :
                'min-w-[220px]'
                } basis-[32%] md:min-w-[285px] md:basis-[30%] lg:basis-[18%] xl:basis-[10%] 2xl:basis-[13%] ${
                twoRows ?
                'embla-slide-two-rows min-w-[190px] md:min-w-[190px] md:basis-[30%]' :
                ''
                }`}>

										<div
                  className={`flex flex-col ${
                  twoRows ? 'h-full gap-2' : ''
                  }`}>

											{pair.map((product, index) =>
                  <Link
                    key={index}
                    to={generateProductLink(
                      product,
                      productType,
                      storeParams
                    )}
                    state={{
                      amplitudePageSource: isClient ?
                      window.location.href :
                      ''
                    }}
                    onClick={() =>
                    handleClick(product, pairIndex * 2 + index)}

                    className={cn(
                      twoRows ? 'embla-slide-inner min-h-0 flex-1' : ''
                    )}>

													<CardComponent
                      product={product}
                      isSlider={twoRows ? false : true}
                      isDoubleSlider={twoRows}
                      width={twoRows ? '95px' : ''}
                      lazyLoad
                      priority="low"
                      storeParams={storeParams} />

												</Link>
                  )}
										</div>
									</CarouselItem>
              )}
							</CarouselContent>
						</Carousel>}

				</div>
				{products.length === 0 &&
        <div className="h-[300px] md:h-[400px]">
						<PlentyHeading as="h7">
							{t('carousel_products_no_results_subtitle')}
						</PlentyHeading>
						<PlentyBody size="lg">
							{t('carousel_products_no_results_title')}
						</PlentyBody>
					</div>}

			</div>
		</>);

}

function generateProductLink(
product: any,
productType: string,
storeParams?: string[])
{
  const storeParam = storeParams?.length ?
  `&store=${storeParams.join(',')}` :
  '';

  if (productType === 'clerk') {
    return `/product/${product.style_id}${storeParam}`;
  } else {
    return `/product/${product.styleNumber}?style=${product.previewVariant.styleVariantKey}${storeParam}`;
  }
}