import { useState } from 'react'
import { PriceTag } from '#app/components/common/product-card/price-tag'
import ProductCardLabel from '#app/components/common/product-card/product-card-label'
import PlentyBody from '#app/components/typography/plenty-body'
import { cn } from '#app/utils/misc'
import { type StyleVariant } from '#types/product'
import ImageComponent from '../image-component'

interface ProductCardProps {
	product: any
	isSlider?: boolean
	lazyLoad?: boolean
	priority?: 'auto' | 'low' | 'high' | undefined
}

const ProductCard = ({
	product,
	isSlider = false,
	priority = 'auto',
	lazyLoad,
}: ProductCardProps) => {
	const activeStyleVariant = product.styleVariants.find(
		(it: StyleVariant) => it.active,
	)

	const normalizePrice = (priceObj: any) => {
		if (priceObj && priceObj.value) return priceObj
		return {
			value: { centAmount: 0, currencyCode: 'DEFAULT', fractionDigits: 2 },
		}
	}

	const priceToUse = activeStyleVariant
		? normalizePrice(activeStyleVariant.lowestPrice)
		: normalizePrice(product.previewVariant.prices[0])

	const [isHovered, setIsHovered] = useState(false)
	const hasSecondImage = product.previewVariant.images.length > 1

	const handleMouseEnter = () => setIsHovered(true)
	const handleMouseLeave = () => setIsHovered(false)

	const cardClassNames = `flex cursor-pointer flex-col gap-4 ${
		isSlider ? 'min-w-[285px]' : ''
	}`

	return (
		<div className={cardClassNames} key={product.previewVariant.key}>
			{product.previewVariant.images[0] && (
				<div
					className="relative flex"
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<div className="special-wrap">
						<ImageComponent
							size="original"
							src={product.previewVariant.images[1].url}
							alt={product.name}
							className={cn(
								`aspect-product-image h-full  rounded-lg object-cover transition duration-300 ease-in-out `,
								isSlider ? ' min-h-[265px] md:min-h-[416px]' : '',
								isHovered && hasSecondImage ? 'opacity-0' : 'opacity-100',
							)}
							loading={lazyLoad ? 'lazy' : 'eager'}
							fetchpriority={priority}
						/>
						{hasSecondImage && (
							<ImageComponent
								size="original"
								src={product.previewVariant.images[0].url}
								alt={product.name}
								className={cn(
									`aspect-product-image h-full  rounded-lg object-cover transition duration-300 ease-in-out`,
									isSlider ? ' min-h-[265px] md:min-h-[416px]' : '',
									isHovered ? 'opacity-100' : '',
								)}
								loading={lazyLoad ? 'lazy' : 'eager'}
								fetchpriority={priority}
							/>
						)}
					</div>
					<ProductCardLabel
						activeStyleVariant={activeStyleVariant}
						price={priceToUse}
					/>
				</div>
			)}

			<div className="flex flex-col">
				<PlentyBody size="lg" className="font-bold">
					{product.previewVariant.attributes
						.find((attr: { name: string }) => attr.name === 'brand')
						?.value.toUpperCase() ?? 'Unknown Brand'}
				</PlentyBody>
				<div className="flex flex-col">
					<PlentyBody size="lg">{product.name.en}</PlentyBody>
					{priceToUse && (
						<span>
							<PriceTag
								activeStyleVariant={activeStyleVariant}
								price={priceToUse}
							/>
						</span>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProductCard
